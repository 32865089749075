var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "create-booking-err-length-passenger-qh",
      "title": _vm.$t('flight.errLengthPax'),
      "title-class": "".concat(!_vm.overAfterAll && _vm.canBeSaved ? 'text-warning' : 'text-danger', " font-medium-4 font-weight-bolder"),
      "centered": "",
      "no-close-on-backdrop": "",
      "body-class": "",
      "size": "lg"
    },
    on: {
      "show": _vm.showModal,
      "hide": _vm.hideModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.Back')) + " ")]), !_vm.overAfterAll && _vm.canBeSaved ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill",
          attrs: {
            "size": "md",
            "variant": "outline-info"
          },
          on: {
            "click": function click($event) {
              return _vm.handleCreateBooking(_vm.payType);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.payType === 'payNow' ? _vm.$t('flight.confirmChange&continue') : _vm.$t('flight.confirmChange&book')) + " ")]) : _vm._e()];
      }
    }])
  }, [_c('b-overlay', {
    staticClass: "my-2",
    attrs: {
      "show": _vm.isEmpty(_vm.errorLists),
      "rounded": "sm",
      "no-fade": "",
      "spinner-variant": "primary",
      "variant": "transparent",
      "opacity": "0.85"
    }
  }, [_c('b-card', [_c('p', {
    staticClass: "font-weight-bolder font-medium-3 text-airline"
  }, [_vm._v(" Hành khách: ")]), _vm.overAfterAll ? _vm._l(_vm.errorListsShow, function (err, index) {
    return _c('div', {
      key: index
    }, [_c('p', {
      staticClass: "p-75 border-danger rounded-lg",
      class: index !== _vm.errorListsShow.length - 1 ? 'mb-75' : 'mb-0'
    }, [err.type === 'one' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.overLength1', {
      lastName: "".concat(err.pax.lastName),
      firstName: "".concat(err.pax.firstName)
    })) + " ")]) : _vm._e(), err.type === 'double' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.overLength2', {
      adtLastName: "".concat(err.adult.lastName),
      adtFirstName: "".concat(err.adult.firstName),
      infLastName: "".concat(err.infant.lastName),
      infFirstName: "".concat(err.infant.firstName)
    })) + " ")]) : _vm._e()])]);
  }) : _vm._e(), !_vm.overAfterAll && _vm.canBeSaved ? _vm._l(_vm.canSavedLists, function (err, index2) {
    var _vm$$t;
    return _c('div', {
      key: index2
    }, [_c('p', {
      staticClass: "p-75 border-warning rounded-lg",
      class: index2 !== _vm.canSavedLists.length - 1 ? 'mb-75' : 'mb-0'
    }, [err.type === 'double' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.overLength3', (_vm$$t = {
      infLastName: "".concat(err.infant.lastName),
      infFirstName: "".concat(err.infant.firstName)
    }, _vm$$t["infLastName"] = "".concat(err.infant.lastName), _vm$$t.strNameInfant = "".concat(err.strNameInfant), _vm$$t))) + " ")]) : _vm._e()])]);
  }) : _vm._e()], 2), _vm.overAfterAll ? _c('b-alert', {
    staticClass: "px-1 py-50 my-50",
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_c('p', {
    staticClass: "mb-0 text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.callBooker1')) + " ")])]) : _vm._e(), !_vm.overAfterAll && _vm.canBeSaved ? _c('b-alert', {
    staticClass: "px-1 py-50 my-50",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('p', {
    staticClass: "mb-0 text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.callBooker2')) + " ")])]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }