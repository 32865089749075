<template>
  <b-modal
    id="create-booking-err-length-passenger-qh"
    :title="$t('flight.errLengthPax')"
    :title-class="`${!overAfterAll && canBeSaved ? 'text-warning' : 'text-danger'} font-medium-4 font-weight-bolder`"
    centered
    no-close-on-backdrop
    body-class=""
    size="lg"
    @show="showModal"
    @hide="hideModal"
  >
    <template #modal-footer="{ close }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill"
        @click="close()"
      >
        {{ $t('flight.Back') }}
      </b-button>

      <b-button
        v-if="!overAfterAll && canBeSaved"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-info"
        class="center rounded-pill"
        @click="handleCreateBooking(payType)"
      >
        {{ payType === 'payNow' ? $t('flight.confirmChange&continue') : $t('flight.confirmChange&book') }}
      </b-button>
    </template>

    <b-overlay
      :show="isEmpty(errorLists)"
      rounded="sm"
      no-fade
      class="my-2"
      spinner-variant="primary"
      variant="transparent"
      opacity="0.85"
    >
      <b-card>
        <p class="font-weight-bolder font-medium-3 text-airline">
          Hành khách:
        </p>
        <template v-if="overAfterAll">
          <div
            v-for="(err, index) of errorListsShow"
            :key="index"
          >
            <p
              class="p-75 border-danger rounded-lg"
              :class="index !== errorListsShow.length -1 ? 'mb-75' : 'mb-0'"
            >
              <span v-if="err.type === 'one'">
                {{ $t('flight.overLength1', { lastName: `${err.pax.lastName}`, firstName: `${err.pax.firstName}` }) }}
              </span>
              <span v-if="err.type === 'double'">
                {{ $t('flight.overLength2', { adtLastName: `${err.adult.lastName}`, adtFirstName: `${err.adult.firstName}`, infLastName: `${err.infant.lastName}`, infFirstName: `${err.infant.firstName}` }) }}
              </span>
            </p>
          </div>
        </template>
        <template v-if="!overAfterAll && canBeSaved">
          <div
            v-for="(err, index2) of canSavedLists"
            :key="index2"
          >
            <p
              class="p-75 border-warning rounded-lg"
              :class="index2 !== canSavedLists.length -1 ? 'mb-75' : 'mb-0'"
            >
              <span v-if="err.type === 'double'">
                {{ $t('flight.overLength3', { infLastName: `${err.infant.lastName}`, infFirstName: `${err.infant.firstName}`, infLastName: `${err.infant.lastName}`, strNameInfant: `${err.strNameInfant}` }) }}
              </span>
            </p>
          </div>
        </template>
      </b-card>

      <b-alert
        v-if="overAfterAll"
        variant="danger"
        show
        class="px-1 py-50 my-50"
      >
        <p class="mb-0 text-center">
          {{ $t('flight.callBooker1') }}
        </p>
      </b-alert>

      <b-alert
        v-if="!overAfterAll && canBeSaved"
        variant="warning"
        show
        class="px-1 py-50 my-50"
      >
        <p class="mb-0 text-center">
          {{ $t('flight.callBooker2') }}
        </p>
      </b-alert>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal, BOverlay, BButton, BCard, BAlert,
} from 'bootstrap-vue'
import {
  ref, toRefs, watch,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

export default {
  components: {
    BModal,
    BCard,
    BOverlay,
    BButton,
    BAlert,
  },
  props: {
    errorLists: {
      type: Array,
      default: () => [],
    },
    payType: {
      type: String,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const loading = ref(true)
    const { errorLists } = toRefs(props)

    const errorListsShow = ref([])
    const overAfterAll = ref(false)
    const canBeSaved = ref(false)
    const canSavedLists = ref([])

    watch(() => errorLists.value, val => {
      if (!overAfterAll.value) {
        const overLimitOnePaxArr = val.filter(item => item.type === 'one' && item.isOverLimit)
        const overLimitInfPaxArr = val.filter(item => item.type === 'double' && item.isOverLimit)

        // Check có pax quá giới hạn ký tự thì show
        if (!isEmpty(overLimitOnePaxArr)) {
          overLimitOnePaxArr.forEach(p => {
            // errorListsShow.value.push(`Khách hàng ${p.pax.lastName} ${p.pax.firstName} có thông tin quá dài.`)
            errorListsShow.value.push(p)
          })
          overAfterAll.value = true
        }

        // Check có parent&infant quá giới hạn ký tự thì show
        if (isEmpty(overLimitOnePaxArr) && overLimitInfPaxArr.find(p => !p.canModify)) {
          overLimitInfPaxArr.forEach(p => {
            const adult = p.pax.find(p => p.paxType === 'ADULT')
            const infant = p.pax.find(p => p.paxType === 'INFANT')
            // errorListsShow.value.push(`Khách hàng ${adult.lastName} ${adult.firstName} và em bé ${infant.lastName} ${infant.firstName} có thông tin quá dài.`)
            errorListsShow.value.push({
              type: 'double',
              adult,
              infant,
            })
          })

          overAfterAll.value = true
        }

        // Nếu không có giới hạn và list paxs có thể modify name
        if (isEmpty(overLimitOnePaxArr) && overLimitInfPaxArr.every(p => p.canModify)) {
          overLimitInfPaxArr.forEach(p => {
            const inf = p.pax.find(i => i.paxType === 'INFANT')
            // canSavedLists.value.push(`Thông tin hành khách quá dài, trong trường hợp này Em bé ${inf.lastName} ${inf.firstName} sẽ được đổi tên thành ${inf.lastName} ${p.strNameInfant}`)
            canSavedLists.value.push({
              type: 'double',
              infant: inf,
              strNameInfant: p.strNameInfant,
            })
          })
          canBeSaved.value = true
        }
      }
    })

    function hideModal() {
      // loading.value = false
    }

    function showModal() {
      errorListsShow.value = []
      canSavedLists.value = []
      overAfterAll.value = false
      canBeSaved.value = false
    }

    function handleCreateBooking(payType) {
      emit('handleCreateBooking', payType)

      this.$bvModal.hide('create-booking-err-length-passenger-qh')
    }

    return {
      loading,
      errorListsShow,
      isEmpty,

      overAfterAll,
      canBeSaved,

      showModal,
      hideModal,
      handleCreateBooking,

      canSavedLists,
    }
  },
}
</script>
